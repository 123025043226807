
/*  Global styles
/* ======================================= */

* {
    box-sizing: border-box;
}

body, html {
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    font-family: 'NationalLight', sans-serif;
    background: black;
    color: white;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.section, .inner {
    display: block;
    position: relative;
    width: 100%;
    overflow: hidden;
}

.inner-full {
    margin: 0 auto;
    max-width: 1080px;
    padding: 32px 12px;
    min-height: 100vh;
}

.inner-half {
    width: 50%;
    padding: 32px 12px;
    float: left;
}

a {
    font-family: 'NationalMedium', sans-serif;
    color: white;
    text-decoration: none;
}

strong {
    font-family: 'NationalMedium';
}

#view {
    display: block;
    position: relative;
    opacity: 0;
    transition: opacity 0.3s ease-out;

    &.show {
        opacity: 1;
    }
}

hr {
    margin: 32px 0;
}

.canvas_container {
    width: 100%;
    height: 100%;
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 998;
}

header {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    max-width: 1280px;
    height: 100px;
    margin: 0 auto;
    z-index: 999;
}

.menu-button {
    position: absolute;
    right: 100px;
    top: 0;
    background-color: rgba(0,0,0,.75);
    border: 2px solid #ffe038;
    border-radius: 43.5px;
    box-sizing: border-box;
    color: #fff;
    font-family: NationalMedium;
    font-style: normal;
    font-weight: 300;
    height: 50px;
    margin-top: 60px;
    width: 130px;
    transition: all 0.1s ease-out;
    cursor: pointer;
    text-transform: uppercase;

    &:hover {
        background: $yellow;
        color: black;
    }
}

.sound-toggle {
    position: absolute;
    right: 32px;
    top: 0;
    width: 45px;
    height: 45px;
    margin-top: 63px;
    background: transparent url('../assets/images/sound-play.png') center center no-repeat;
    background-size: 100% auto;
    cursor: pointer;
    border: none;
    outline: none;
    cursor: pointer;

    &.muted {
        background: transparent url('../assets/images/sound-mute.png') center center no-repeat;
        background-size: 100% auto;
    }

    &:hover {
        opacity: 0.8;
    }
}

.user-guide-wrapper {
    background: black url('../assets/images/ribbon-bg-right.jpg') right top no-repeat;
    background-size: auto 500px;
    // background: black;
    color: white;

    h2 {
        font-family: 'NationalBlack', sans-serif;
        text-transform: uppercase;
        font-size: 55px;
        margin: 0 0 50px;
        padding: 0;
    }

    h3 {
        font-family: 'NationalBlack', sans-serif;
        font-size: 28px;
        text-transform: uppercase;
    }

    p {
        // font-size: 28px;
    }

    a {
        color: white;
        text-decoration: none;
        font-family: NationalBlack;

        &.light-link {
            color: $blue;
            font-family: NationalLight;
        }
    }

    ol {

    }

    li {
        font-size: 18px;
        // display: block;
        margin: 0 0 10px;
    }
}

.user-guide-title {

    background: transparent url('../assets/images/ribbon-bg.jpg') left bottom no-repeat;
    background-size: auto 50%;

    h2 {
        font-size: 100px;
        padding: 25% 0;
    }

    span {
        // display: block;
    }
}

.menu-wrapper {
    display: block;
    position: fixed;
    background: $yellow;
    color: black;
    text-align: right;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    transition: all 0.5s;
    overflow: scroll;
    transform: translate(-100%, 0px);

    &.showing {
        // width: 100%;
        transform: translate(0%, 0px);
    }

    .menu--close-btn {
        font-family: NationalMedium;
        font-size: 20px;
        position: absolute;
        right: 10%;
        top: 20px;
        background: none;
        border: none;
        outline: none;
        cursor: pointer;
        text-transform: uppercase;

        &:hover {
            text-decoration: underline;
        }
    }

    .menu--items {
        display: block;
        margin: 10% 0 0 0;
        padding: 0 10%;

        li {
            display: block;
            margin: 0;
            padding: 0;
        }
    }

    a {
        text-transform: uppercase;
        color: black;
        text-decoration: none;
        display: block;
        font-family: NationalBlack;
        font-size: 100px;
        line-height: 1;
        padding: 0;
        text-decoration: none;
        transition: .3s;

        &:hover {
            color: white;
        }
    }
}

.guide-picture {
    display: block;
    margin: 32px auto;
    max-width: 300px;
    width: 100%;

    & > img {
        display: block;
        width: 100%;
    }

    & > span {
        font-family: 'NationalLight';
        font-size: 12px;
        text-transform: uppercase;
    }
}

.control-picture {
    display: block;
    margin: 50px 0 20px;
    max-width: 300px;
    width: 100%;
}

.controls-wsad {
    display: block;
    margin: 0;
    padding: 0;
    text-transform: uppercase;

    li {
        display: block;
        margin: 0;
        padding: 0 0 0 50px;
        font-family: NationalMedium;
        font-size: 22px;
        position: relative;

        span {
            font-family: NationalBlack;
            position: absolute;
            left: 0;
            top: block;
        }
    }
}

.controls-list {
    display: block;
    margin: 0;
    padding: 0;
    text-transform: uppercase;

    li {
        display: block;
        margin: 0;
        font-family: NationalMedium;
        font-size: 22px;
    }
}

.controls-icon-list {
    display: block;
    margin: 0;
    padding: 0;

    li {
        display: block;
        margin: 0 0 40px;
        padding: 0 0 0 280px;
        position: relative;
        min-height: 60px;
    }

    img {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto 0;
        width: 250px;
        display: block;
    }

    span {
        display: block;
        max-width: 280px;
        width: 100%;
    }
}

.modal-bg {
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.5);
    z-index: 2000;
    pointer-events: none;
    opacity: 0;
    transform: translate(0px, 100%);
    transition: .5s;
    cursor: pointer;

    &.showing {
        opacity: 1;
        transform: translate(0px, 0%);
        pointer-events: auto;
    }
}

.modal-wrapper {
    z-index: 2001;
    display: block;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    max-width: 640px;
    // max-height: 320px;
    width: 100%;
    // height: 100%;
    background: white;
    border-radius: 20px;
    overflow: hidden;
    color: black;
    font-family: 'NationalMedium';
    opacity: 0;
    transform: translate(-50%, 100%);
    transition: .5s;

    &.showing {
        opacity: 1;
        transform: translate(-50%, -50%);
        pointer-events: auto;
    }

    a {
        color: inherit;
        text-decoration: underline;
    }

    button {
        background: black;
        color: white;
        border: none;
        outline: none;
        padding: 12px 12px;
        border-radius: 5px;
        cursor: pointer;
        transition: all 0.1s ease-out;

        &:hover {
            background: $yellow;
            color: black;
        }
    }
}
.modal-content {
    padding: 32px;
    img {
        max-width: 240px;
        width: 100%;
        display: block;
    }
}

.touch-enter-button {
    display: none;
    width: 100px;
    height: 100px;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    // background: $yellow;
    z-index: 1000;
    border: none;
    outline: none;
    background: transparent;

    &.showing {
        display: block;
    }
}

.maps-wrapper,
.support-wrapper {
    background: black;
    color: white;

    h2 {
        font-family: 'NationalBlack', sans-serif;
        text-transform: uppercase;
        font-size: 55px;
        margin: 0 0 50px;
        padding: 0;
    }

    h3 {
        font-family: 'NationalBlack', sans-serif;
        font-size: 28px;
        text-transform: uppercase;
    }

    p {
        // font-size: 28px;
    }

    a {
        color: white;
        text-decoration: none;
        font-family: NationalBlack;

        &.light-link {
            color: $blue;
            font-family: NationalLight;
        }
    }
}

.maps-wrapper {
    background: black url('../assets/images/ribbons-top.jpg') top right no-repeat;
    background-size: 35% auto;
}

.support-wrapper {
    background: black url('../assets/images/ribbons-left.jpg') bottom left no-repeat;
    background-size: 20% auto;
}

.support-title,
.maps-title {

    h2 {
        font-size: 100px;
        padding: 15% 0 0 0;
        margin: 0 0 30px;
    }

    span {
    }
}

.icon-smile {
    display: inline;
    line-height: 1;
    height: 17px;
    vertical-align: middle;
    position: relative;
    top: -1px;
}

.map-list {
    display: block;
    margin: 0;
    padding: 0;

    li {
        display: block;
        margin: 0 0 30px;
        padding: 0;
    }

    img {
        display: block;
        width: 100%;
    }
}