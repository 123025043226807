
@font-face{
  font-family: NationalMedium;
  src: local('national-2-web-medium'), url(../assets/fonts/national-2-web-medium.woff2) format('woff2');
}
@font-face{
  font-family: NationalBlack;
  src: local('national-2-web-black'), url(../assets/fonts/national-2-web-black.woff2) format('woff2');
}
@font-face{
  font-family: NationalLight;
  src: local('national-2-web-light'), url(../assets/fonts/national-2-web-light.woff2) format('woff2');
}
@font-face{
  font-family: NationalRegular;
  src: local('national-2-web-regular'), url(../assets/fonts/national-2-web-regular.woff2) format('woff2');
}
@font-face{
  font-family: NationalThin;
  src: local('national-2-web-thin'), url(../assets/fonts/national-2-web-thin.woff2) format('woff2');
}