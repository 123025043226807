
.inner-full {
    max-width: 100%;
}

.user-guide-title {
    h2 {
        font-size: 50px;
        padding: 50% 0;
    }
}

.menu-wrapper {

    .menu--items {
        padding: 30% 10%;
    }

    a {
        font-size: 60px;
    }
}